import styled, { css } from 'styled-components';

import React from 'react';

export const TournamentItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1vw;
  background: rgba(10, 10, 91, 0.7);
  border-radius: 20px;
  position: absolute;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  border: ${props =>
    props.position === 0 && props.score
      ? '8px solid #8af3bc'
      : '2px solid #FFFFFF'};

  ${props =>
    props.position === 0 &&
    props.score &&
    css`
      box-shadow: 0px 0px 24px 0px #8af3bc;
    `};
  ${props =>
    css`
      top: calc(
        100vh -
          ${(100 / (props.total + 1)) * (props.total - props.index + 1) +
          props.index * 5}vh
      );

      @media (min-width: 1024px) {
        padding: 2vw;
      }
    `};
`;

export const StreamerText = styled.span`
  margin-left: 2vw;
  font-style: normal;
  font-weight: bold;
  font-size: 4vw;
  line-height: 5vh;
  text-transform: uppercase;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 3vw;
    margin-left: 1vw;
  }

  @media (min-width: 1200px) {
    margin-left: 1vw;
  }
`;

export const ScoreTagWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 3vw;

  @media (min-width: 1024px) {
    margin-right: 1vw;
  }
  @media (max-width: 768px) {
    margin-right: 1vw;
  }
`;

export const ScoreTag = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  border: 3px solid rgba(239, 242, 247, 0.7);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  border-radius: 11px;
  font-weight: bold;
  font-size: 3vw;
  color: #062779;
  background: ${props =>
    props.position === 0 && props.score ? '#f1c71b' : '#4FD3F1'};

  @media (max-width: 768px) {
    font-size: 2vw;
  }
`;

export const ScoreImage = styled.img`
  width: 10vw;

  @media (min-width: 1024px) {
    width: 10vw;
  }

  @media (max-width: 768px) {
    width: 8vw;
  }

  margin-left: ${props => (props.position > 0 ? '1vw' : 0)};
`;

export const ScoreItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 10vh;

  @media (min-width: 1200px) {
    height: 8vh;
  }
`;

export const ScoreBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 27px;
  margin: 2vw;

  @media (min-width: 1024px) {
    margin: 1vw;
  }

  @media (max-width: 768px) {
    margin: 1vw;
  }
`;

export const ScoreBar = styled.div`
  width: ${props => props.progress + '%'};
  transition: width 2s ease;
  height: 1.5vh;
  background: ${props =>
    props.position === 0 && props.score ? '#f1c71b;' : '#4FD3F1'};
  ${props =>
    props.position === 0 &&
    props.score &&
    css`
      box-shadow: 0px 0px 24px #f1c71b;
    `};
  border-radius: 27px;
`;

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TournamentLeaderboradItem = React.forwardRef((props, ref) => {
  const {
    streamer,
    position,
    setRef,
    index,
    img,
    score,
    scoreSign,
    progress,
    totalItems,
  } = props;

  const getOrdinalSuffix = index => {
    switch (Number(String(index).slice(-1))) {
      case 0:
        return 'st';
      case 1:
        return 'nd';
      case 2:
        return 'rd';
      case 4:
        return 'th';
      default:
        return 'th';
    }
  };

  return (
    <TournamentItem
      score={score}
      scoreSign={scoreSign}
      position={position}
      id={streamer}
      index={index}
      total={totalItems}
      ref={el => setRef(position, el)}
    >
      <ScoreImage
        position={position}
        alt={position + 1 + getOrdinalSuffix(position) + ' place'}
        src={img}
      />
      <ScoreItem>
        <ResultWrapper>
          <StreamerText>{streamer}</StreamerText>
          <ScoreTagWrapper>
            <ScoreTag position={position} score={score}>
              {score || 0}
              {scoreSign || ''}
            </ScoreTag>
          </ScoreTagWrapper>
        </ResultWrapper>
        <ScoreBarWrapper>
          <ScoreBar position={position} progress={progress} score={score} />
        </ScoreBarWrapper>
      </ScoreItem>
    </TournamentItem>
  );
});

export default TournamentLeaderboradItem;
