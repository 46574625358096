import {
  HuntCenteredContainer,
  HuntDescCenteredBottomContainer,
  HuntDescCenteredContainer,
  HuntDescCenteredTopContainer,
  HuntDescTitle,
  HuntDescTopElement,
  HuntDescValue,
  HuntSlotsBox,
  HuntSlotsContainer,
} from '../components/HuntOverlay';
import { useEffect, useState } from 'react';

import { calculateStopAutoScrolling } from '../utils/CalculateStopAutoScrolling';
import { fetchHuntByStreamerKey } from '../data/fetchHunts';
import { io } from 'socket.io-client';
import { useParams } from 'react-router-dom';

const Overlay = () => {
  const [huntData, setHuntData] = useState({});
  const [stopAutoScrolling, setStopAutoScrolling] = useState(true);
  let { key } = useParams();

  useEffect(() => {
    async function fetchData() {
      const initialData = await fetchHuntByStreamerKey(key);
      setHuntData(initialData.hunt);
      calculateStopAutoScrolling(setStopAutoScrolling);
    }
    fetchData();
  }, [key]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API);
    socket.on('connect', () => socket.emit('join-hunt', { key }));
    socket.on('hunt-update', ({ hunt }) => {
      setHuntData(hunt);
      calculateStopAutoScrolling(setStopAutoScrolling);
    });

    return () => {
      socket.disconnect();
    };
  }, [key]);

  if (!huntData?._id) return null;

  return (
    <HuntCenteredContainer>
      <HuntDescCenteredContainer>
        <HuntDescCenteredTopContainer>
          {huntData.startingBalance && (
            <HuntDescTopElement>
              <HuntDescTitle>Start</HuntDescTitle>
              <HuntDescValue>
                {huntData.currency}
                {huntData.startingBalance.toLocaleString('en-GB')}
              </HuntDescValue>
            </HuntDescTopElement>
          )}
          {huntData.slots && huntData.slots.length > 0 && (
            <HuntDescTopElement>
              <HuntDescTitle>Bonuses collected</HuntDescTitle>
              <HuntDescValue>{huntData.slots.length}</HuntDescValue>
            </HuntDescTopElement>
          )}
        </HuntDescCenteredTopContainer>
        <HuntSlotsContainer id='SlotContainer'>
          <HuntSlotsBox id='SlotBox' stopScroll={stopAutoScrolling}>
            {huntData &&
              huntData.slots.map((item, key) => {
                if ((!item.result && !item.betSize) || !item.name) return '';

                const resultStructure = item.result ? (
                  <b>{`${huntData.currency} ${item.result.toLocaleString(
                    'en-GB'
                  )}`}</b>
                ) : (
                  `${huntData.currency} ${item.betSize.toLocaleString('en-GB')}`
                );

                return (
                  <div key={`item1-${key + 1}`}>
                    {key + 1}. {item.name} - {resultStructure}
                  </div>
                );
              })}

            {!stopAutoScrolling &&
              huntData &&
              huntData.slots.map((item, key) => {
                if ((!item.result && !item.betSize) || !item.name) return '';

                const resultStructure = item.result ? (
                  <b>{`${huntData.currency} ${item.result.toLocaleString(
                    'en-GB'
                  )}`}</b>
                ) : (
                  `${huntData.currency} ${item.betSize.toLocaleString('en-GB')}`
                );

                return (
                  <div key={`item2-${key + 1}`}>
                    {key + 1}. {item.name} - {resultStructure}
                  </div>
                );
              })}
          </HuntSlotsBox>
        </HuntSlotsContainer>
        <HuntDescCenteredBottomContainer>
          <img
            style={{ marginTop: 10 }}
            alt='Powered by CasinoGrounds'
            src='https://res.cloudinary.com/casinogrounds/image/upload/v1661154624/cg/logo-powered-by-cg.png'
          />
          {huntData.casinoId && (
            <HuntDescTopElement>
              <HuntDescTitle>Current casino</HuntDescTitle>
              <HuntDescValue>{huntData.casinoId}</HuntDescValue>
            </HuntDescTopElement>
          )}
        </HuntDescCenteredBottomContainer>
      </HuntDescCenteredContainer>
    </HuntCenteredContainer>
  );
};

export default Overlay;
