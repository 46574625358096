export const fetchTournamentById = async id => {
  const res = await fetch(
    process.env.REACT_APP_API + '/tournaments/public/' + id
  );

  if (res.status === 200) {
    return await res.json();
  } else {
    throw new Error('failed to fetch tournament by id: ' + res.status);
  }
};
