import { gsap } from 'gsap';
import { createRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import styled from 'styled-components';
import TournamentLeaderboradItem from './TournamentLeaderboardItem';

const TournamentContainer = styled.div`
  position: relative;
`;

const LeaderboardResults = props => {
  const [tournamentData, setTournamentData] = useState({});
  const [initialData, setInitialData] = useState(props.initialData);
  const [isUpdate, setIsUpdate] = useState(false);
  const { id } = useParams();
  const prevTournamentData = usePrevious(tournamentData);
  const [total, setTotal] = useState(initialData.results?.length || 0);
  let itemRefs = Array.from({ length: total }, () => createRef());

  const scoreImgCloudinaryTemplate =
    'https://res.cloudinary.com/casinogrounds/image/upload/cg/leaderboard_[position].png';

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }
  const getPrevPosition = item =>
    prevTournamentData?.results?.find(i => i.streamer === item.streamer)
      ?.position;

  useEffect(() => {
    const calcProgressBar = data => {
      const maxWin = data?.results.reduce(
        (prev, current) => (prev.score > current.score ? prev : current),
        0
      );
      data.results.map((item, index) => {
        if (maxWin.score > 0 && maxWin.score === item.score) {
          data.results[index] = { ...item, progress: 100 };
          return item;
        } else {
          const progressUnit = maxWin.score / 100;
          data.results[index] = {
            ...item,
            progress: Number((item.score / progressUnit).toFixed(2)),
          };
          return item;
        }
      }, data);
      return data;
    };

    const dataWithProgress = calcProgressBar(initialData);
    setTournamentData(dataWithProgress);
    setTotal(initialData.results?.length);

    const socket = io(process.env.REACT_APP_API);
    socket.on('connect', () => socket.emit('join-tournament', { id }));
    socket.on('tournament-update', ({ tournament }) => {
      const dataWithProgress = calcProgressBar(tournament, true);
      setIsUpdate(true);
      setTournamentData(dataWithProgress);
    });

    return () => {
      socket.disconnect();
    };
  }, [id]);

  const updateData = (tournament, ...fieldsToUpdate) => {
    setInitialData(prevState => {
      const updatedResults = prevState.results.map(r => {
        const streamerToUpdate = tournament?.results?.find(
          i => i.streamer === r.streamer
        );

        if (!streamerToUpdate) {
          return { ...r, undefined };
        }

        const updateFields = fieldsToUpdate.map(f =>
          f === 'scoreImg'
            ? Object.assign(
                {},
                {
                  [f]: scoreImgCloudinaryTemplate.replace(
                    '[position]',
                    streamerToUpdate.position + 1
                  ),
                }
              )
            : Object.assign({}, { [f]: streamerToUpdate[f] })
        );

        if (r.streamer === streamerToUpdate.streamer) {
          return {
            ...r,
            ...Object.assign({}, ...updateFields),
          };
        }
        return r;
      });
      return { ...prevState, results: updatedResults };
    });
  };

  useEffect(() => {
    if (isUpdate) {
      updateData(tournamentData, 'score', 'progress', 'scoreImg', 'position');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournamentData]);

  useLayoutEffect(() => {
    if (isUpdate) {
      const refIndex = streamer =>
        [...Array(total).keys()].find(
          i => itemRefs[i]?.current?.getAttribute('id') === streamer
        );
      const streamerByPosition = (data, position) =>
        data?.results?.find(i => i.position === position)?.streamer;

      tournamentData?.results?.forEach(item => {
        const prevPosition = getPrevPosition(item);

        if (prevPosition !== item.position) {
          const animation = gsap.fromTo(
            itemRefs[refIndex(item.streamer)]?.current,
            {
              top: Math.floor(
                itemRefs[refIndex(item.streamer)]?.current.offsetTop
              ),
            },
            {
              top: Math.floor(
                itemRefs[
                  refIndex(
                    streamerByPosition(prevTournamentData, item.position)
                  )
                ]?.current.offsetTop
              ),
              ease: 'expo.inOut',
              duration: 2,
            }
          );
          animation.invalidate();
        }
      });
      updateData(tournamentData, 'position');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournamentData]);

  const setRef = (key, ref) => {
    itemRefs[key].current = ref;
  };

  return (
    <TournamentContainer>
      {initialData.results?.length > 0 &&
        itemRefs.length &&
        initialData.results
          .slice(0, total.length)
          .map((item, index) => (
            <TournamentLeaderboradItem
              streamer={item.streamer}
              score={item.score}
              scoreSign={item.scoreSign}
              position={item.position}
              key={index}
              setRef={setRef}
              index={index}
              img={item.scoreImg}
              progress={item.progress}
              totalItems={total}
            />
          ))}
    </TournamentContainer>
  );
};

export default LeaderboardResults;
