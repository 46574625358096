import {
  HuntContainer,
  HuntDescHeader,
  HuntDescLowerContainer,
  HuntDescMainContainer,
  HuntDescTitle,
  HuntDescUpperContainer,
  HuntDescValue,
  HuntGrid,
  HuntSlotsBox,
  HuntSlotsContainer,
} from '../components/HuntOverlay';
import { useEffect, useState } from 'react';

import { calculateStopAutoScrolling } from '../utils/CalculateStopAutoScrolling';
import { fetchHuntByStreamerKey } from '../data/fetchHunts';
import { io } from 'socket.io-client';
import { poweredByCGLogo } from '../utils/svg';
import { useParams } from 'react-router-dom';

const Overlay = () => {
  const [huntData, setHuntData] = useState({});
  const [stopAutoScrolling, setStopAutoScrolling] = useState(true);
  let { key } = useParams();

  useEffect(() => {
    async function fetchData() {
      const initialData = await fetchHuntByStreamerKey(key);
      setHuntData(initialData.hunt);
      calculateStopAutoScrolling(setStopAutoScrolling);
    }
    fetchData();
  }, [key]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API);
    socket.on('connect', () => socket.emit('join-hunt', { key }));
    socket.on('hunt-update', ({ hunt }) => {
      setHuntData(hunt);
      calculateStopAutoScrolling(setStopAutoScrolling);
    });

    return () => {
      socket.disconnect();
    };
  }, [key]);

  if (!huntData?._id) return null;

  return (
    <HuntContainer>
      <HuntGrid>
        <HuntDescMainContainer>
          <HuntDescUpperContainer>
            <HuntDescHeader>Bonus hunt</HuntDescHeader>
            {huntData.startingBalance && (
              <>
                <HuntDescTitle>Start</HuntDescTitle>
                <HuntDescValue>
                  {huntData.currency}
                  {huntData.startingBalance.toLocaleString('en-GB')}
                </HuntDescValue>
              </>
            )}
            {huntData.slots && huntData.slots.length > 0 && (
              <>
                <HuntDescTitle>Bonuses collected</HuntDescTitle>
                <HuntDescValue>{huntData.slots.length}</HuntDescValue>
              </>
            )}
            {huntData.casinoId && (
              <>
                <HuntDescTitle>Playing at</HuntDescTitle>
                <HuntDescValue>{huntData.casinoId}</HuntDescValue>
              </>
            )}
          </HuntDescUpperContainer>
          <HuntDescLowerContainer>{poweredByCGLogo}</HuntDescLowerContainer>
        </HuntDescMainContainer>
        <HuntSlotsContainer id='SlotContainer'>
          <HuntSlotsBox id='SlotBox' stopScroll={stopAutoScrolling}>
            {huntData &&
              huntData.slots.map((item, key) => {
                if ((!item.result && !item.betSize) || !item.name) return '';

                const resultStructure = item.result ? (
                  <b>{`${huntData.currency} ${item.result.toLocaleString(
                    'en-GB'
                  )}`}</b>
                ) : (
                  `${huntData.currency} ${item.betSize.toLocaleString('en-GB')}`
                );

                return (
                  <div key={`item1-${key + 1}`}>
                    {key + 1}. {item.name} - {resultStructure}
                  </div>
                );
              })}

            {!stopAutoScrolling &&
              huntData &&
              huntData.slots.map((item, key) => {
                if ((!item.result && !item.betSize) || !item.name) return '';

                const resultStructure = item.result ? (
                  <b>{`${huntData.currency} ${item.result.toLocaleString(
                    'en-GB'
                  )}`}</b>
                ) : (
                  `${huntData.currency} ${item.betSize.toLocaleString('en-GB')}`
                );

                return (
                  <div key={`item2-${key + 1}`}>
                    {key + 1}. {item.name} - {resultStructure}
                  </div>
                );
              })}
          </HuntSlotsBox>
        </HuntSlotsContainer>
      </HuntGrid>
    </HuntContainer>
  );
};

export default Overlay;
