import { createRef, useEffect, useState } from 'react';

import LeaderboardResults from '../components/LeaderboardResults';
import { fetchTournamentById } from '../data/fetchTournaments';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

export const TournamentContainer = styled.div`
  padding: 20px;
  // background: url(https://res.cloudinary.com/casinogrounds/image/upload/cg/leaderboard-bg.png)
  //     center,
  //   linear-gradient(180deg, #0a0a5b 28.89%, rgba(10, 10, 91, 0) 100%);
  // background-blend-mode: multiply;
  // height: 96vh;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
`;

export const LeaderboardImage = styled.img`
  display: block;
  width: calc(100% - 40vw);
  margin: 2vw auto;

  @media (min-width: 769px) {
    width: calc(100% - 60vw);
  }

  @media (min-width: 1200px) {
    width: calc(100% - 50vw);
  }
`;

const TournamentLeaderboradOverlay = () => {
  const [error, setError] = useState(false);
  const [initialData, setInitialData] = useState({});
  const { id } = useParams();
  const imgRef = createRef();
  const scoreImgCloudinaryTemplate =
    'https://res.cloudinary.com/casinogrounds/image/upload/cg/leaderboard_[position].png';

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchTournamentById(id);
        data.results.map(
          i =>
            (i.scoreImg = scoreImgCloudinaryTemplate.replace(
              '[position]',
              i.position + 1
            ))
        );
        setInitialData(data);
      } catch (error) {
        console.error('error: ', error);
        setError(true);
      }
    }

    fetchData();
  }, [id]);

  if (error)
    return (
      <div>
        Error while loading tournament with ID: {id}. Check whether tournamentId
        exists.
      </div>
    );

  if (initialData.status !== 'running') {
    return <div>Tournament must be open to see the leaderboard</div>;
  }

  return (
    <TournamentContainer>
      {initialData.leaderboardImg && (
        <LeaderboardImage
          alt='Slot Streamer Showdown'
          src={initialData.leaderboardImg}
          ref={imgRef}
        />
      )}

      <LeaderboardResults initialData={initialData} />
    </TournamentContainer>
  );
};

export default TournamentLeaderboradOverlay;
