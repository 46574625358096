import styled from 'styled-components';

export const HuntContainer = styled.div`
  background-color: #4f4f4f;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  height: ${props =>
    props.standalone ? 'calc(100% - 20px);' : 'calc(100% - 40px);'};
  width: ${props =>
    props.standalone ? 'calc(100% - 20px);' : 'calc(100% - 40px);'};
  padding: ${props => (props.standalone ? '10px' : '20px')};
`;

export const HuntGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100%;
`;

export const HuntDescMainContainer = styled.div`
  padding-right: 10px;
  display: grid;
`;

export const HuntDescUpperContainer = styled.div`
  align-self: start;
`;

export const HuntDescLowerContainer = styled.div`
  align-self: end;

  && svg {
    width: calc(100% - 10px);
    margin: 20px 10px 0 0;
  }
`;

export const HuntSlotsContainer = styled.div`
  background-color: #262626;
  padding: 10px 30px;
  position: relative;
  overflow: hidden;
  ${props => props.standalone && `height: calc(100% - 20px);`}
`;

export const HuntSlotsBox = styled.div`
  position: absolute;
  height: calc(100%-20px);
  top: 0;
  color: #ffbf44;
  font-size: 22px;
  font-weight: bold;

  && b {
    color: white;
  }

  && div {
    margin-bottom: 10px;
  }

  ${props =>
    !props.stopScroll &&
    `
    animation: floatText 25s infinite linear;
    @keyframes floatText {
      from {
        transform: translateY(0%);
      }
      to {
        transform: translateY(-50%);
      }
    }
  `}
`;

export const HuntDescText = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`;

export const HuntDescCenteredTopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const HuntDescTopElement = styled.div`
  display: grid;
  grid-template-rows: 1fr 1.5fr;
  :nth-child(2n) div {
    text-align: end;
  }
`;

export const HuntDescCenteredContainer = styled.div`
  display: grid;
  grid-auto-rows: 70px 1fr 70px;
  height: 96vh;
`;

export const HuntCenteredContainer = styled(HuntContainer)`
  padding: 10px 20px;
  height: calc(100% - 20px);
`;

export const HuntDescCenteredBottomContainer = styled(
  HuntDescCenteredTopContainer
)`
  align-self: center;

  && svg {
    width: 30vh;
    align-self: center;
  }
  padding: 10px 0 0 0;
`;

export const HuntDescHeader = styled(HuntDescText)`
  font-size: 20px;
`;

export const HuntDescTitle = styled(HuntDescText)`
  color: #ffbf44;
  font-size: 14px;
  margin-top: 10px;
`;

export const HuntDescValue = styled(HuntDescText)`
  font-size: 22px;
`;
