import {
  HuntContainer,
  HuntSlotsBox,
  HuntSlotsContainer,
} from '../components/HuntOverlay';
import { useEffect, useState } from 'react';

import { calculateStopAutoScrolling } from '../utils/CalculateStopAutoScrolling';
import { fetchHuntByStreamerKey } from '../data/fetchHunts';
import { io } from 'socket.io-client';
import { useParams } from 'react-router-dom';

const SmallOverlay = () => {
  const [huntData, setHuntData] = useState({});
  const [stopAutoScrolling, setStopAutoScrolling] = useState(true);
  let { key } = useParams();

  useEffect(() => {
    async function fetchData() {
      const initialData = await fetchHuntByStreamerKey(key);
      setHuntData(initialData.hunt);
      calculateStopAutoScrolling(setStopAutoScrolling);
    }
    fetchData();
  }, [key]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API);
    socket.on('connect', () => socket.emit('join-hunt', { key }));
    socket.on('hunt-update', ({ hunt }) => {
      setHuntData(hunt);
      calculateStopAutoScrolling(setStopAutoScrolling);
    });

    return () => {
      socket.disconnect();
    };
  }, [key]);

  if (!huntData?._id) return null;

  return (
    <HuntContainer standalone>
      <HuntSlotsContainer id='SlotContainer' standalone>
        <HuntSlotsBox id='SlotBox' stopScroll={stopAutoScrolling}>
          {huntData &&
            huntData.slots.map((item, key) => {
              if ((!item.result && !item.betSize) || !item.name) return '';

              const resultStructure = item.result ? (
                <b>{`${huntData.currency} ${item.result.toLocaleString(
                  'en-GB'
                )}`}</b>
              ) : (
                `${huntData.currency} ${item.betSize.toLocaleString('en-GB')}`
              );

              return (
                <div key={`item1-${key + 1}`}>
                  {key + 1}. {item.name} - {resultStructure}
                </div>
              );
            })}

          {!stopAutoScrolling &&
            huntData &&
            huntData.slots.map((item, key) => {
              if ((!item.result && !item.betSize) || !item.name) return '';

              const resultStructure = item.result ? (
                <b>{`${huntData.currency} ${item.result.toLocaleString(
                  'en-GB'
                )}`}</b>
              ) : (
                `${huntData.currency} ${item.betSize.toLocaleString('en-GB')}`
              );

              return (
                <div key={`item2-${key + 1}`}>
                  {key + 1}. {item.name} - {resultStructure}
                </div>
              );
            })}
        </HuntSlotsBox>
      </HuntSlotsContainer>
    </HuntContainer>
  );
};

export default SmallOverlay;
