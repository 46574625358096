import { useEffect, useState } from 'react';

import { fetchHuntByStreamerKey } from '../data/fetchHunts';
import { io } from 'socket.io-client';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { winnerCup } from '../utils/svg';

const WinnerContainer = styled.div`
  display: grid;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const WinnerBox = styled.div`
  align-self: center;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
`;

const CornerContanier = styled.div`
  align-self: center;
  min-width: calc(180px);
`;

const CornerDataBox = styled.div`
  background-color: #262626;
  border: 10px solid #4f4f4f;
  color: white;
  padding: 20px;
`;

const CornerDataBoxTitle = styled.div`
  color: #ffbf44;
  font-size: 15px;
  text-transform: uppercase;
`;

const CornerDataBoxValue = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const LeftDataBox = styled(CornerDataBox)`
  border-right: 0;
`;
const RightDataBox = styled(CornerDataBox)`
  border-left: 0;
`;

const MiddleContainer = styled.div`
  background-color: #ffbf44;
  color: black;
  border-radius: 5px;
  text-align: center;
  padding: 30px;
`;

const WinnerName = styled.div`
  font-size: 30px;
  font-weight: bold;
  border-bottom: ${props => (props.winAmount ? '2px solid black' : 'none')};
  padding-bottom: 10px;
`;

const WinAmount = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const HuntWinner = () => {
  const [huntData, setHuntData] = useState({});
  let { key } = useParams();
  console.log('key: ', key);

  useEffect(() => {
    async function fetchData() {
      const initialData = await fetchHuntByStreamerKey(key);
      setHuntData(initialData.hunt);
    }
    fetchData();
  }, [key]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API);
    socket.on('connect', () => socket.emit('join-hunt', { key }));
    socket.on('hunt-update', ({ hunt }) => {
      setHuntData(hunt);
    });

    return () => {
      socket.disconnect();
    };
  }, [key]);

  if (!huntData?._id) return null;

  return (
    <WinnerContainer>
      <WinnerBox>
        <CornerContanier>
          <LeftDataBox>
            <CornerDataBoxTitle>result:</CornerDataBoxTitle>
            <CornerDataBoxValue>
              {huntData.currency}
              {huntData.slots
                .reduce(function (accumulator, currentValue) {
                  return accumulator + currentValue.result;
                }, 0)
                .toLocaleString('en-GB')}
            </CornerDataBoxValue>
          </LeftDataBox>
        </CornerContanier>

        <MiddleContainer>
          <div>{winnerCup}</div>
          <WinnerName winAmount={huntData.winAmount}>
            {huntData.winnerName ? huntData.winnerName : '?'}
          </WinnerName>
          {huntData.winAmount && (
            <WinAmount>
              {huntData.currency +
                '' +
                huntData.winAmount.toLocaleString('en-GB')}
            </WinAmount>
          )}
        </MiddleContainer>

        <CornerContanier>
          <RightDataBox>
            <CornerDataBoxTitle>closest guess:</CornerDataBoxTitle>
            <CornerDataBoxValue>
              {huntData.closestGuess
                ? huntData.currency +
                  '' +
                  huntData.closestGuess.toLocaleString('en-GB')
                : '?'}
            </CornerDataBoxValue>
          </RightDataBox>
        </CornerContanier>
      </WinnerBox>
    </WinnerContainer>
  );
};

export default HuntWinner;
