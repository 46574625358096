import { Route, Switch } from 'react-router-dom';

import CenteredHunt from './views/CenteredHunt';
import DefaultHunt from './views/DefaultHunt';
import HuntWinner from './views/HuntWinner';
import React from 'react';
import SmallHunt from './views/SmallHunt';
import TournamentLeaderboard from './views/TournamentLeaderboard';

const Navigation = () => {
  return (
    <Switch>
      <Route path='/hunts/:key' component={DefaultHunt} />
      <Route path='/hunts-centered/:key' component={CenteredHunt} />
      <Route path='/hunts-small/:key' component={SmallHunt} />
      <Route path='/hunts-winner/:key' component={HuntWinner} />
      <Route path='/tournaments/:id' component={TournamentLeaderboard} />
    </Switch>
  );
};
export default Navigation;
